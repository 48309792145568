import icon from '@plone/volto/icons/list-bullet.svg'
//import BlockEdit from './Block/Edit';
//import BlockView from './Block/View';
import BlockEdit from './Block/EditBlock';
import BlockView from './Block/ParallaxView';
export default function applyConfig(config) {
    config.blocks.blocksConfig.parallax_block = {
        id: 'parallax_block',
        title: 'Parallax_Block',
        edit: BlockEdit,
        view: BlockView,
        icon: icon,
        group: 'common',
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
    };
    return config;
};
