import icon from '@plone/volto/icons/alert.svg'
//import BlockEdit from './Block/Edit';
//import BlockView from './Block/View';
import BlockEdit from './Block/EditBlock';
import BlockView from './Block/HighLightView';
export default function applyConfig(config) {
    config.blocks.blocksConfig.highlight = {
        id: 'highlight',
        title: 'Hightlight-Box',
        edit: BlockEdit,
        view: BlockView,
        icon: icon,
        group: 'text',
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
    };
    return config;
};
