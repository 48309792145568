
const IParallaxLayerSchema = ({
    title: "Layer",
    fieldsets: [
        {
            id: 'default',
            title: 'Default',
            fields: ['image', 'speed'],
        },
    ],

    properties: {
        image: {
            title: "Image",
            widget: 'object_browser',
            mode: 'image',
            allowExternals: true,
            return: 'single',
        },
        speed: {
            title: "Speed",
            type: 'number',
        }
    },
    required: [],
});

const IParallaxSchema = {
    title: "ParallaxSchema",
    fieldsets: [
        {
            id: 'default',
            title: 'Default',
            fields: ['speed', 'classes'],
        },
        {
            id: 'content',
            title: 'Layers',
            fields: ['layers'],
          },
    ],

    properties: {
        speed: {
            title: "speed",
            type: 'number',
        },
        classes: {
            title: "Custom Classes",
            type: 'string',
        },
        layers: {
            title: "Image Layers",
            schema: IParallaxLayerSchema,
            widget: 'object_list'
          },

    },
    required: [],
};


export default IParallaxSchema;
