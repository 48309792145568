import { defineMessages } from 'react-intl';

const kliniken = [
  {"@id":"marker0", "title":"Dresden", "latitude":51.0459904, "longitude":13.7822208, "icon":0},
  {"@id":"marker1", "title":"Hoyerswerda", "latitude":51.4245719, "longitude":14.1356248, "icon":0},
  {"@id":"marker2", "title":"Riesa", "latitude":51.2991989, "longitude":13.2543556, "icon":0},
  {"@id":"marker3", "title":"Kamenz", "latitude":51.2823455, "longitude":14.0976426, "icon":0},
  {"@id":"marker4", "title":"Bautzen", "latitude":51.1854804, "longitude":14.414885, "icon":0},
  {"@id":"marker5", "title":"Görlitz", "latitude":51.1505994, "longitude":14.9565071, "icon":0},
  {"@id":"marker6", "title":"Borna", "latitude":51.1505994, "longitude":14.9565071, "icon":0},
  {"@id":"marker7", "title":"Meißen", "latitude":51.1505994, "longitude":14.9565071, "icon":0},
  {"@id":"marker8", "title":"Freital", "latitude":51.1505994, "longitude":14.9565071, "icon":0},
  {"@id":"marker9", "title":"Pirna", "latitude":51.1505994, "longitude":14.9565071, "icon":0},
  {"@id":"marker10", "title":"Kreischa", "latitude":51.1505994, "longitude":14.9565071, "icon":0},
  {"@id":"marker11", "title":"Freiberg", "latitude":51.1505994, "longitude":14.9565071, "icon":0},
  {"@id":"marker12", "title":"Zittau", "latitude":51.1505994, "longitude":14.9565071, "icon":0},
  {"@id":"marker13", "title":"Zwisckau", "latitude":51.1505994, "longitude":14.9565071, "icon":0},
  {"@id":"marker14", "title":"Aue", "latitude":51.1505994, "longitude":14.9565071, "icon":0},
];


export const ILeafletMarkerSchema = (intl) => ({
  title: intl.formatMessage(messages.marker),
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'latitude', 'longitude', 'icon', 'image', 'website'],
    },
  ],

  properties: {
    title: {
      title: intl.formatMessage(messages.title),
      type: 'string',
    },
    latitude: {
      title: intl.formatMessage(messages.latitude),
      type: 'number',
    },
    longitude: {
      title: intl.formatMessage(messages.longitude),
      type: 'number',
    },
    icon: {
      title: intl.formatMessage(messages.icon),
      type: 'number',
      widget: 'icon_select',
    },
    image: {
      title: intl.formatMessage(messages.image),
      widget: 'object_browser',
      mode: 'image',
      allowExternals: true,
      return: 'single',
    },
    website: {
      title: intl.formatMessage(messages.website),
      type: 'string',
    },
  },
  required: [],
});

export const ILeafletMapSchema = (intl) => ({
  title: intl.formatMessage(messages.map),
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['posx', 'posy', 'overlay_url'],
    },
    {
      id: 'content',
      title: intl.formatMessage(messages.content),
      fields: ['markers'],
    },
  ],

  properties: {
    posx: {
      title: "X-POS",
      type: 'number',
      initialValue: 2.5,
    },
    posy: {
      title: "Y-POS",
      type: 'number',
      initialValue: 2.5,
    },
    zoom: {
      title: intl.formatMessage(messages.zoom),
      type: 'number',
      initialValue: 9,
      maximum: 18,
      minimum: 0,
    },
    height: {
      title: intl.formatMessage(messages.height),
      initialValue: '866px',
    },
    overlay_url: {
      title: intl.formatMessage(messages.overlay),
      widget: 'object_browser',
      mode: 'image',
      allowExternals: true,
      return: 'single',
    },
    markers: {
      title: intl.formatMessage(messages.markers),
      schema: ILeafletMarkerSchema(intl),
      widget: 'object_list',
      initialValue: kliniken
    },
  },
  required: [],
});

const messages = defineMessages({
  map: {
    id: 'Map',
    defaultMessage: 'Map',
  },
  latitude: {
    id: 'Latitude',
    defaultMessage: 'Latitude (y)',
  },
  longitude: {
    id: 'Longitude',
    defaultMessage: 'Longitude (x)',
  },
  zoom: {
    id: 'Zoom',
    defaultMessage: 'Zoom',
  },
  height: {
    id: 'Height',
    defaultMessage: 'Height',
  },
  width: {
    id: 'Width',
    defaultMessage: 'Width',
  },
  icon: {
    id: 'Icon',
    defaultMessage: 'Icon',
  },
  image: {
    id: 'Image',
    defaultMessage: 'Image',
  },
  website: {
    id: 'Website',
    defaultMessage: 'Website',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  marker: {
    id: 'Marker',
    defaultMessage: 'Marker',
  },
  markers: {
    id: 'Markers',
    defaultMessage: 'Markers',
  },
  content: {
    id: 'Content',
    defaultMessage: 'Content',
  }, 
  overlay: {
    id: 'Overlay',
    defaultMessage: 'Overlay',
  },
  north_east: {
    id: 'North_East',
    defaultMessage: 'North East (y, x)',
  },
  sout_west: {
    id: 'South_West',
    defaultMessage: 'South West (y, x)',
  },
  mitgliedseit: {
    id: 'Mitglied_Seit',
    defaultMessage: 'Klinik ist Mitglied Seit',
  }
});
