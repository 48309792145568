import React from 'react';
import { RenderBlocks } from '@plone/volto/components';
import '../style/parallax-style.less'

const View = (props) => {
    const { data } = props;
    const metadata = props.metadata || props.properties;
    //const style_string = {zindex: data.z-index}
    return (
        <div className='highlight-gradient'>
            <RenderBlocks {...props} metadata={metadata} content={data?.data || {}} />
        </div>
    );
};

export default View;