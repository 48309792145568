/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, List, Segment, Image } from 'semantic-ui-react';
import { map } from 'lodash';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { UniversalLink } from '@plone/volto/components';
import { flattenToAppURL, addAppURL } from '@plone/volto/helpers';
import { NavLink } from 'react-router-dom';
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  const { siteActions = [] } = useSelector(
    (state) => ({
      siteActions: state.actions?.actions?.site_actions,
    }),
    shallowEqual,
  );

  return (
    <>
      <div className='footer-decoration' />
      <div className='footer-wrapper'>
        <Segment
          role="contentinfo"
          vertical
          padded
          inverted
          textAlign="left"
          id="footer"
        >
          <Container>
            <List horizontal inverted>
              <div role="listitem" className="item">
                <Image
                  alt={"Footer Img 1"}
                  title={"Footer Img 1"}
                  src={flattenToAppURL("/bilder/logo-lga-inter-cert.png/@@images/image")}
                />
              </div>
              <div role="listitem" className="item">
                <Image
                  alt={"Footer Img 2"}
                  title={"Footer Img 2"}
                  src={flattenToAppURL("/bilder/logo-zertifikat-iso9001.png/@@images/image")}
                />
              </div>
              <div role="listitem" className="item">
                @ 2023 Neurovaskuläres Netzwerk
              </div>
            </List>
            <List horizontal inverted className={"footer-list"}>
              <div role="listitem" className="item">
                <NavLink to={'/kontakt'} className={"item"}>
                  Kontakt
                </NavLink>
              </div>
              <div role="listitem" className="item">
                <NavLink to={'/login'} className={"item"}>
                  Anmelden
                </NavLink>
              </div>
              <div role="listitem" className="item">
                <NavLink to={'/impressum'} className={"item"}>
                  Impressum
                </NavLink>
              </div>
              <div role="listitem" className="item">

                <NavLink to={'/datenschutz'} className={"item"}>
                  Datenschutz
                </NavLink>
              </div>

            </List>
          </Container>
        </Segment>
      </div>

    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
        * @static
        */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
