/**
 * File view component.
 * @module components/theme/View/FileView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container as SemanticContainer } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import PdfView from './PdfView.jsx';
import VideoView from './VideoView.jsx';
import downloadIcon from './download.svg';

/**
 * File view component class.
 * @function FileView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */

const FileView = ({ content }) => {

  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;
  let contentView;

  switch (content.file && content.file['content-type']) {
    case 'application/pdf':
      contentView = (
        <PdfView content={content} />
      );
      break;

    case 'video/mp4':
      contentView = (
        <VideoView content={content} />
      );
      break;
    // Add more cases for other file types here
    default:
      contentView = (
        <>
          {content.description && (
            <p className="documentDescription">{content.description}</p>
          )}
        </>
      );
      break;
  }

  return (
    <Container className="view-wrapper">
      <h1 className="documentFirstHeading">
        {content.title}
        {content.subtitle && ` - ${content.subtitle}`}
      </h1>
      {contentView}
      {content.file?.download && (
        <a href={flattenToAppURL(content.file.download)}>
          <span className="download-icon" style={{ backgroundImage: `url(${downloadIcon})` }}></span>
          {content.file.filename}
        </a>
      )}
    </Container>
  );
};


/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
FileView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    file: PropTypes.shape({
      download: PropTypes.string,
      filename: PropTypes.string,
      'content-type': PropTypes.string, // Adding 'content-type' to propTypes
    }),
  }).isRequired,
};

export default FileView;
