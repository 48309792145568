
const ILayerSchema = {
    title: "Layer",
    fieldsets: [
        {
            id: 'default',
            title: 'Default',
            fields: ['classes'],
        },
    ],
    properties: {
        classes: {
            title: 'Styling Klasse',
            type: 'string',
            widget: 'textarea',
        },
    },
    required: [],
};
export default ILayerSchema;
