import React from 'react';
import { RenderBlocks } from '@plone/volto/components';
import '../style/parallax-style.less'
import { ParallaxBanner } from 'react-scroll-parallax';

const View = (props) => {
    const { data } = props;
    const metadata = props.metadata || props.properties;
    return (
        <>
            <ParallaxBanner
                layers={[
                    {
                        speed: Number(data.speed),
                        children: (
                            <div className="parallax-blocks">
                                <RenderBlocks {...props} metadata={metadata} content={data?.data || {}} />

                            </div>
                        ),
                    },
                ]}
                className={" parallax " + data.classes}
            />
        </>
    );
};

export default View;