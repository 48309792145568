import React from 'react';
import '../style/style.css'
import { NavLink } from 'react-router-dom';

const View = (props) => {
  const { data } = props;
  return (
    <div className='full-width grey'>
      <div className='grid-container homepage-grid'>
        <NavLink to={"/das-netzwerk/struktur/koordinierendes-zentrum-dnvc"} >
          <div className='grid-item blue-gradient'>
            <p>Koordinierendes Zentrum – DNVC
            </p>
          </div>
        </NavLink>

        <NavLink to={"/das-netzwerk/struktur/sos-net-kooperationskliniken"} >
          <div className='grid-item blue-gradient'>
            <p>SOS-NET</p>
            <p>Kooperationskliniken</p>
            <p>mit zertifizierter Stroke-Unit</p>
          </div>
        </NavLink>

        <NavLink to={"/das-netzwerk/struktur/sos-telenet-mit-kooperationskliniken"} >
          <div className='grid-item blue-gradient'>
            <p>SOS-TeleNET</p>
            <p>Kooperationskliniken</p>
          </div>
        </NavLink>

        <NavLink to={"/das-netzwerk/struktur/sos-care"} >
          <div className='grid-item blue-gradient'>
            <p>﻿SOS-Care</p>
          </div>
        </NavLink>

      </div>

    </div>
  );

};
export default View;